import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import PostPreview from "../components/PostPreview";
import styled from 'styled-components'

const TagCloud = styled.div`
  display: inline-flex;
`;

const TagPageTemplate = ({data, pageContext}) => {
  const posts = data?.allMarkdownRemark?.edges;
  if(!posts) return null;

  return (
    <Layout>
      <h1>
      Posts tagged with {pageContext.tag}
      </h1>
      {posts.map((post) => 
      <PostPreview {...post} />
      )}
      <TagCloud>
        {pageContext.allTags.map((tag) => {
          <Link key={tag} to={`/tags/${tag.toLowerCase().replace(' ', '-')}`}>
          </Link>
        })}
      </TagCloud>
    </Layout>
  )
}

export default TagPageTemplate;

// export const tagPageQuery = graphql`
//   query TagPage($tag: String) {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//     allMarkdownRemark(
//       limit: 1000
//       sort: { fields: [frontmatter___year], order: DESC }
//       filter: { frontmatter: { tags: { in: [$tag] } } }
//     ) {
//       totalCount
//       edges {
//         node {
//           fields {
//             slug
//           }
//           frontmatter {
//             title
//             templateKey
//             description
//             featuredimage {
//               childImageSharp {
//                 fluid(maxWidth: 2048, quality: 100) {
//                   ...GatsbyImageSharpFluid
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `;
