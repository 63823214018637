import React from 'react'
import styled from "styled-components";
import { theme } from "../theme";
import { useStaticQuery, graphql, Link } from "gatsby";

const Title = styled.h4`
  text-transform: uppercase;
  font-size: 1.2em;
  margin: 0;
  padding-right: 1rem;
`;

const TextPost = styled.article`
  display: flex;
  padding: 2rem 1rem;
  flex-direction: column;
`;

const TextPostHead = styled.div`
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
`;

const Date = styled.span`
  font-style: italic;
`;

const Description = styled.div``;

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
  `
const StyledAnchor = styled.a`
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const TextPostPreview = ({fields, frontmatter, hideYear}) => {
  return (
    <TextPost key={fields.title}>
      <TextPostHead>
        <StyledAnchor href={frontmatter?.article?.publicURL || frontmatter?.externalLink}>
          <Title>{frontmatter.title}</Title>
        </StyledAnchor>
        <Date>{frontmatter.year}</Date>
      </TextPostHead>
      <Description>{frontmatter.description}</Description>
    </TextPost>
  )
}


export default TextPostPreview