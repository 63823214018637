import React from 'react';
import ImagePostPreview from './ImagePostPreview';
import TextPostPreview from './TextPostPreview';

type PostFrontmatter = {
  templateKey: 'text-post' | 'image-post'
  year: number;
  description: string;
  tags: string[];
  featuredimage: {
    childImageSharp: {
      fluid: any
    }
  }
}

type PostFields = { 
  slug: string
}

type Props = {
  frontmatter : PostFrontmatter;
  fields: PostFields;
}

const PostPreview = (props: {node: Props, hideYear?: boolean;}) => {
  const {node } = props
  return node?.frontmatter?.templateKey === 'text-post' ? <TextPostPreview {...node} /> : <ImagePostPreview {...node} />;
};

export default PostPreview;